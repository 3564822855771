import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: {
      name: 'index',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login.vue'),
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index.vue'),

  },
  {
    name:'notFoundHome',
    path: '/',
    redirect: {
      name: 'home',
    },
    component:() => import('./view/routerView.vue'),
    children:[
      {
        name: 'noticeDetails',
        path: '/noticeDetails',
        component: () => import('./view/noticeDetails.vue'),
      },
      {
        name: 'notices',
        path: '/notices',
        component: () => import('./view/notices.vue'),
      },
      {
        name: 'team',
        path: '/team',
        component: () => import('./view/team.vue'),
      },
      {
        name: 'changePwd',
        path: '/changePwd',
        component: () => import('./view/changePwd.vue'),
      },
      {
        name: 'withdrawal',
        path: '/withdrawal',
        component: () => import('./view/withdrawal.vue'),
      },
      {
        name: 'historyBill',
        path: '/historyBill',
        component: () => import('./view/historyBill.vue'),
      },
      {
        name: 'liquidityOrder',
        path: '/liquidityOrder',
        component: () => import('./view/liquidityOrder.vue'),
      },
      {
        name: 'contractOrder',
        path: '/contractOrder',
        component: () => import('./view/contractOrder.vue'),
      },
      {
        name: 'recharge',
        path: '/recharge',
        component: () => import('./view/recharge.vue'),
      },
      {
        name: 'auth',
        path: '/auth',
        component: () => import('./view/auth.vue'),
      },
      {
        name: 'liquidity_pool',
        path: '/liquidity_pool',
        component: () => import('./view/liquidity_pool.vue'),
      },
      {
        name: 'contract',
        path: '/contract/:id',
        component: () => import('./view/contract.vue'),
        props: true,
      },{
        name: 'contract2',
        path: '/contract2/:id',
        component: () => import('./view/contract2.vue'),
        props: true,
      },
      {
        name: 'me',
        path: '/me',
        component: () => import('./view/MeNew.vue'),
      },
      {
        name: 'liquidity',
        path: '/liquidity',
        component: () => import('./view/liquidity.vue'),
      },
      {
        name: 'home',
        path: '/home',
        component: () => import('./view/home.vue'),
      },
      {
        name: 'news',
        path: '/news',
        component: () => import('./view/News.vue'),
      },
      {
        name: 'NewsDetails',
        path: '/NewsDetails/:id',
        component: () => import('./view/NewsDetails.vue'),
      },

      {
        name: 'register',
        path: '/register',
        component: () => import('./view/register.vue'),
      },
      {
        name: 'level',
        path: '/level',
        component: () => import('./view/level.vue'),
      },
      {
        name: 'card',
        path: '/card',
        component: () => import('./view/card.vue'),
      },
      {
        name: 'defend',
        path: '/defend',
        component: () => import('./view/defend.vue'),
      },
      {
        name: 'defendOrder',
        path: '/defendOrder',
        component: () => import('./view/defendOrder.vue'),
      },
    ]
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
